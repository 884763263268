import { Component, ViewChild, HostListener } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { cameraSettingsService } from '../../services/camera-settings.service';
import { camera } from '../../../../@shared/models/camera';
import { MatDialog } from '@angular/material/dialog';
import { DialogCameraComponent } from '../dialog-camera/dialog-camera.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cameraSettings',
  templateUrl: './camera-settings.component.html',
  styleUrls: ['./camera-settings.component.scss'],
})
export class CameraSettingsComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<camera>;
  displayedColumns: string[] = ['code', 'desc', 'watchzone', 'location', 'threshold', 'isComplete', 'active', 'options'];
  pageSize = 7;
  pageSizeOptions = [7, 10, 14, 30, 50];
  lenPaginator = 0;
  searchRequestSubscriptions: Subscription[] = [];
  private element_data: camera[] = [];

  constructor(
    private cameraSettingsService: cameraSettingsService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.dataSource = new MatTableDataSource<camera>(this.element_data);
    this.getEveryCameras();
    this.adjustPageSize(window.innerHeight);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.translatePaginatorLabels();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustPageSize(window.innerWidth);
  }

  adjustPageSize(viewportHeight: number) {
    if (viewportHeight > 1440) {
      this.pageSize = 20;
    } else if (viewportHeight > 1080) {
      this.pageSize = 14;
    } else if (viewportHeight > 700) {
      this.pageSize = 10;
    } else if (viewportHeight > 500) {
      this.pageSize = 7;
    } else {
      this.pageSize = 3;
    }

    if (this.paginator) {
      this.paginator.pageSize = this.pageSize;
      this.paginator.pageSizeOptions = this.pageSizeOptions;
    }
  }

  getEveryCameras() {
    this.element_data = [];

    this.cameraSettingsService.getCameras().subscribe({
      next: (data: camera[]) => {
        const temp = Object.keys(data).map((key) => data[key]);
        for (const elem of temp) {
          elem.isCompleted = true;
          this.element_data.push(elem);
        }
        this.dataSource.data = this.element_data;
      },
      error: () => {
        console.error('something went wrong.');
      },
    });

    this.cameraSettingsService.getCamerasPending().subscribe({
      next: (data: any[]) => {
        const temp = Object.keys(data).map((key) => data[key]);
        for (let elem of temp) {
          this.element_data.push(this.createPendingCam(elem));
        }
        this.dataSource.data = this.element_data;
      },
      error: () => {
        console.error('something went wrong.');
      },
    });
  }

  createPendingCam(elem: string): camera {
    const cam: camera = new camera();
    cam.code = elem;
    cam.isCompleted = false;
    cam.is_active = false;
    return cam;
  }

  isCompleted(element: any) {
    return { 'background-color': element.isCompleted ? 'green' : 'red' };
  }

  slideToggled(cam: camera) {
    const camFound = this.element_data.find((elem) => cam.id === elem.id);
    camFound.is_active = !camFound.is_active;

    this.updateActive(camFound);
  }

  updateActive(cam: camera) {
    this.cameraSettingsService
      .updateCamera(this.cameraSettingsService.createBodyFromCamera(cam), cam.id)
      .subscribe({
        next: () => {
          return;
        },
        error: () => {
          console.error('something went wrong.');
        },
      });
  }

  openDialog(code = null, isModifying = false): void {
    const dialogRef = this.dialog.open(DialogCameraComponent, {
      data: { code, isModifying },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getEveryCameras();
    });
  }

  onTextChange(changedText: string) {
    this.cancelPendingRequests();

    this.element_data = [];
    const searchSub = this.cameraSettingsService.getCameras(changedText).subscribe({
      next: (data: camera[]) => {
        const temp = Object.keys(data).map((key) => data[key]);
        for (const elem of temp) {
          elem.isCompleted = true;
          this.element_data.push(elem);
        }
        this.dataSource.data = this.element_data;
      },
      error: () => {
        console.error('something went wrong.');
      },
    });

    const searchSub2 = this.cameraSettingsService.getCamerasPending(changedText).subscribe({
      next: (data: any[]) => {
        const temp = Object.keys(data).map((key) => data[key]);
        for (const elem of temp) {
          this.element_data.push(this.createPendingCam(elem));
        }
        this.dataSource.data = this.element_data;
      },
      error: () => {
        console.error('something went wrong.');
      },
    });

    this.searchRequestSubscriptions.push(searchSub);
    this.searchRequestSubscriptions.push(searchSub2);
  }

  cancelPendingRequests() {
    this.searchRequestSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  translatePaginatorLabels(): void {
    if (!this.paginator) return;

    this.paginator._intl.itemsPerPageLabel = this.translate.instant('paginator.itemsPerPage');
    this.paginator._intl.nextPageLabel = this.translate.instant('paginator.nextPage');
    this.paginator._intl.previousPageLabel = this.translate.instant('paginator.previousPage');
    this.paginator._intl.firstPageLabel = this.translate.instant('paginator.firstPage');
    this.paginator._intl.lastPageLabel = this.translate.instant('paginator.lastPage');
    this.paginator._intl.getRangeLabel = this.getRangeLabel;

    this.translate.onLangChange.subscribe(() => {
      this.translatePaginatorLabels();
    });

    this.paginator._intl.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of = this.translate ? this.translate.instant('paginator.of') : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, length);
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };
}
